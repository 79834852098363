import React from "react"
import SideBar from "./sidebar"
import "./stylesheets/main.css"
import "./stylesheets/mobile.css"


const Default = () => {
	return(
		<SideBar/>
    )
}


export default Default